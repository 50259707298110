import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import reduxFreeze from 'redux-freeze';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import 'typeface-roboto';
import './index.css';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './ducks';
import rootSaga from './sagas';
import createSagaMiddleware from 'redux-saga';

window.addEventListener('dragover',function(e){
	e.preventDefault();
},false);
window.addEventListener('drop',function(e){
	e.preventDefault();
},false);
const sagas = createSagaMiddleware();
let middleware = [reduxFreeze, logger, sagas];
let store = createStore(rootReducer,{}, applyMiddleware(...middleware));
sagas.run(rootSaga);//run sagas after mounting

const theme = createMuiTheme({
	palette:{
		primary:blue
	},
	// typography: {
	// 	useNextVariants: true,
	// },
});
ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<App />
		</MuiThemeProvider>
	</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
