import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import TextField from '@material-ui/core/TextField';
import css from './Toolbar.module.css';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import Typography from '@material-ui/core/Typography';
import {requestSuggestion,requestAssociatedConcepts, selectConcepts} from 'ducks/ui';
import Typography from '@material-ui/core/Typography';
// const useStyles = makeStyles(theme => ({
//     fab: {
//       margin: theme.spacing(1),
//     },
//     extendedIcon: {
//       marginRight: theme.spacing(1),
//     },
//   }));


class Toolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compoundWord:''
        };
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.generateIcon = this.generateIcon.bind(this);
        this.handleSelectConcept = this.handleSelectConcept.bind(this); 
        
    }
    handleQueryChange(event){
        this.setState({compoundWord:event.target.value});
    }

    generateIcon(){
        let {compoundWord} = this.state;
        this.props.requestSuggestion({query:compoundWord, concepts:[]});
        this.props.requestAssociatedConcepts(compoundWord);
    }
    handleSelectConcept(concept, event){
        let {compoundWord} = this.state;
        let {selectedConcepts} = this.props;
        let selection = [...selectedConcepts];
        if (event.target.checked){
            selection = selection.concat([concept]);
        }else{
            selection = selection.filter(c=>c!==concept);
        }
        this.props.selectConcepts(selection);
        this.props.requestSuggestion({query:compoundWord, concepts:selection});
                
    }
    render() {
        // const classes = useStyles();
        
        return (
            <div className={css.container}>
                <div className={css.mailToolbar}>
                    <TextField 
                        className={css.textField}
                        label="Write words here"
                        value={this.state.compoundWord}
                        placeholder="Write words here"
                        onChange={this.handleQueryChange}/>
                    <Fab color="primary" variant="extended" aria-label="Query"
                        className={css.fabButton}
                        onPointerDown={this.generateIcon} >
                        <NavigationIcon />
                        Generate
                    </Fab>
                </div>
                <div className={css.message}>
                    <Typography variant="caption" display="block" gutterBottom>
                        {this.props.selectedConcepts.length===1?'Please select multiple complementary tags.':this.props.suggestionMsg}
                    </Typography>
                    
                </div>
                <FormGroup className={css.concepts} row>
                    {this.props.associatedConcepts.map((concept,i)=>
                        <FormControlLabel
                            key={i}
                            control={<Checkbox
                                checked={this.props.selectedConcepts.includes(concept)}
                                onChange={this.handleSelectConcept.bind(this,concept)}
                                value={concept}
                                color="primary"
                                
                            />}
                            label={concept.replace(/^\w/,chr=>chr.toUpperCase())}
                    />)}
                </FormGroup>
             
            </div>
            );
        }
    }
    
    Toolbar.propTypes = {
        changeRefineMode:PropTypes.func,
        requestSuggestion:PropTypes.func,
        refineMode:PropTypes.string,
        suggestionMsg:PropTypes.string,
        selectedSuggestion:PropTypes.object,
        selectedComponents:PropTypes.array,
        requestRefineSuggestion:PropTypes.func,
        requestAssociatedConcepts:PropTypes.func,
        associatedConcepts:PropTypes.array,
        selectedConcepts: PropTypes.array,
        selectConcepts:PropTypes.func,
    };
    


    const mapStateToProps = (state) => {
        return {
            selectedSuggestion:state.ui.selectedSuggestion,
            suggestionMsg:state.ui.suggestionMsg,
            selectedComponents:state.ui.selectedComponents,
            associatedConcepts:state.ui.associatedConcepts,
            selectedConcepts:state.ui.selectedConcepts,
            refineMode:state.ui.refineMode
        };
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {
            ...bindActionCreators({
                requestSuggestion,
                requestAssociatedConcepts,
                selectConcepts
            }, dispatch)
        };
    };
    
    
    export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
    