
// action types




export const SELECT_SUGGESTION = 'SELECT_SUGGESTION';
export const SELECT_COMPONENT = 'SELECT_COMPONENT';
// export const SELECT_REFINE_SUGGESTION = 'SELECT_REFINE_SUGGESTION';
export const UPDATE_COMPONENT_ICON = 'UPDATE_COMPONENT_ICON';

export const REQUEST_SUGGESTION = 'REQUEST_SUGGESTION';
export const REQUEST_REFINE_SUGGESTION = 'REQUEST_REFINE_SUGGESTION';

export const RECEIVE_SUGGESTION = 'RECEIVE_SUGGESTION';
export const CHANGE_REFINE_MODE = 'CHANGE_REFINE_MODE';
export const RECEIVE_REFINE_SUGGESTION = 'RECEIVE_REFINE_SUGGESTION';

export const NOTIFY_FAILURE = 'NOTIFY_FAILURE';

export const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';

export const SELECT_CONCEPTS = 'SELECT_CONCEPTS';
export const REQUEST_ASSOCIATED_CONCEPTS = 'REQUEST_ASSOCIATED_CONCEPTS';
export const RECEIVE_ASSOCIATED_CONCEPTS = 'RECEIVE_ASSOCIATED_CONCEPTS';


// actions

export const selectSuggestion = (payload)=>{
	return {type: SELECT_SUGGESTION, payload};
}
export const updateComponent = (payload)=>{
    return {type: UPDATE_COMPONENT_ICON, payload}
}
export const selectComponent = (payload) => {
	return {type: SELECT_COMPONENT, payload};
};
// export const selectRefineSuggestion = (payload) => {
// 	return {type: SELECT_REFINE_SUGGESTION, payload};
// };


export const requestSuggestion = (payload) => {
	return {type: REQUEST_SUGGESTION, payload};
};
export const requestRefineSuggestion = (payload) => {
	return {type: REQUEST_REFINE_SUGGESTION, payload};
};



export const receiveSuggestion = (payload) => {
	return {type: RECEIVE_SUGGESTION, payload};
};
export const changeRefineMode = (payload) => {
	return {type: CHANGE_REFINE_MODE, payload};
};
export const receiveRefineSuggestion = (payload) => {
	return {type: RECEIVE_REFINE_SUGGESTION, payload};
};


export const notifyFailure = (payload)=>{
	return {type:NOTIFY_FAILURE,payload};
};

export const createBookmark = (payload)=>{
    return {type:CREATE_BOOKMARK,payload};
}

export const deleteBookmark = (payload)=>{
    return {type:DELETE_BOOKMARK,payload};
}

export const selectConcepts = (payload)=>{
    return {type:SELECT_CONCEPTS,payload};
}

export const requestAssociatedConcepts = (payload)=>{
    return {type:REQUEST_ASSOCIATED_CONCEPTS,payload};
}
export const receiveAssociatedConcepts = (payload)=>{
    return {type:RECEIVE_ASSOCIATED_CONCEPTS,payload};
}
// reducers
let initState = {
    selectedSuggestion:null,
    suggestionList:[],
    suggestionMsg:'',
    loadingSuggestions:false,
    selectedComponents:[],
    associatedConcepts:[],
    selectedConcepts:[],
    // selectedRefineSuggestion:null,
    refineMode:'layout', //'all', 'style' or 'refine'
    query:'',
    refineSuggestionList:[],
    loadingRefinedSuggestions:false,
    refineSuggestionMsg:'',
    canvasWidth:400,
    canvasHeight:400,
    thumbnailScale:0.5,
    bookmarks:[]
};
 export default (state = initState, action)=>{
	switch (action.type) {
        case CHANGE_REFINE_MODE:
            return{
                ...state,
                refineMode:action.payload
            } 
        case SELECT_SUGGESTION:
            return {
                ...state,
                selectedSuggestion:action.payload
            }
		case SELECT_COMPONENT:
            return {
                ...state,
                selectedComponents:action.payload
            }
        // case SELECT_REFINE_SUGGESTION:
        //     return{
        //         ...state,
        //         selectedRefineSuggestion:action.payload
        //     } 
        case REQUEST_SUGGESTION:
            return{
                ...state,
                loadingSuggestions:true,
                query:action.payload.query,
                appending: action.payload.append,
            }
        case RECEIVE_SUGGESTION:
            return{
                ...state,
                suggestionList:state.appending? state.suggestionList.concat(action.payload):action.payload,
                suggestionMsg:(!state.appending && action.payload.length)>0?'':(state.appending?'':'Sorry, no suggestions could be retrieved for this concept.'),
                refineSuggestionList:action.payload.length>0? state.refineSuggestionList:[],
                selectedSuggestion:(!state.appending&&action.payload.length)>0?action.payload[0]:(state.appending?state.selectedSuggestion:null),
                loadingSuggestions:false,
                appending:false
            } 
        case REQUEST_REFINE_SUGGESTION:
            return {
                ...state,
                loadingRefinedSuggestions:true,
                appending: action.payload.append,
            }
        case RECEIVE_REFINE_SUGGESTION:
            return{
                ...state,
                refineSuggestionList:state.appending? state.refineSuggestionList.concat(action.payload):action.payload,
                refineSuggestionMsg:(!state.appending && action.payload.length)>0?'':(state.appending?'':'Sorry, no refined suggestions could be retrieved.'),
                loadingRefinedSuggestions:false,
                appending:false
            }

        case UPDATE_COMPONENT_ICON:
            return {
                ...state,
                suggestionList:state.suggestionList.map((suggestion, suggestionIndex)=>{
                    if (suggestionIndex!==action.payload.suggestionIndex){
                        return suggestion;
                    }
                    return {
                        ...suggestion,
                        components:suggestion.components.map((component, componentIndex)=>{
                            if (componentIndex!==action.payload.componentIndex){
                                return component;
                            }
                            return {
                                ...component,
                                w:action.payload.w,
                                h:action.payload.h,
                                x:action.payload.x,
                                y:action.payload.y
                            }
                        })
                    }
                })
            } 
        case CREATE_BOOKMARK:
            return {
                ...state,
                bookmarks: state.bookmarks.concat(action.payload)
            }
        case DELETE_BOOKMARK:
            return {
                ...state,
                bookmarks: state.bookmarks.filter(b=>b.id!==action.payload)
            }
        case SELECT_CONCEPTS:
            return {
                ...state,
                selectedConcepts:action.payload
            }
        case RECEIVE_ASSOCIATED_CONCEPTS:
            return {
                ...state, 
                associatedConcepts: action.payload,
                selectedConcepts:[]
            }
		default:
			return state;

	}
};
