import { call, put, takeLatest, select } from 'redux-saga/effects';
// import { push } from 'react-router-redux';
// import { push } from 'react-router-redux';
import io from 'socket.io-client';

import {
	REQUEST_SUGGESTION, 
	REQUEST_REFINE_SUGGESTION,
	REQUEST_ASSOCIATED_CONCEPTS,
	notifyFailure,
	receiveSuggestion,
	receiveRefineSuggestion,
	receiveAssociatedConcepts
} from 'ducks/ui';
import { requestRefineSuggestion } from '../ducks/ui';


let serverURL = process.env.NODE_ENV=='production'?`${window.location.hostname}`:'http://0.0.0.0:3001/';

// selectors

const getRefineMode = (state)=> state.ui.refineMode;
const getSelectedComponents = (state)=> state.ui.selectedComponents;

// socket io connect
const connect = () => {
	let socket = io(serverURL);
	return new Promise((resolve) => {
		socket.on('connect', () => {
			console.log('connected!');
			resolve(socket);
		}).on('disconnect', ()=>{
			console.log('disconnected!');
		});
	});
};

const request = (socket, eventName, ...params)=>{
	return new Promise(resolve=>{
		socket.emit(eventName, ...params, (data)=>{
			resolve(data);
		});
	});
};
function* socketHandler(eventName, callback, action){
	const socket = yield call(connect);
	console.log('socketHandler:', eventName, socket);
	try{
		let response = yield call(request, socket, eventName, action.payload);
		console.log('socketHandler::response', response);
		yield* callback(response);

	}catch(error){

		yield put(notifyFailure(error.message));
	}
	// somehow socket hangs after multiple requests without the following code
	socket.disconnect();
}

function* handleRequestSuggestion(suggestionList){
	yield put(receiveSuggestion(suggestionList));
	if (suggestionList.length>0){
		const refineMode = yield select(getRefineMode)
		const selectedComponents = yield select(getSelectedComponents)
		yield put(requestRefineSuggestion({refineMode, selectedComponents, selectedSuggestion:suggestionList[0]}));
	}
}
function* handleRefineSuggestion(response){
	yield put(receiveRefineSuggestion(response));
}

function* handleAssociatedConcepts(response){
	yield put(receiveAssociatedConcepts(response));
}

export default [
	takeLatest(REQUEST_SUGGESTION, socketHandler, 'suggestion', handleRequestSuggestion),
	takeLatest(REQUEST_REFINE_SUGGESTION, socketHandler, 'refine_suggestion', handleRefineSuggestion),
	takeLatest(REQUEST_ASSOCIATED_CONCEPTS, socketHandler, 'associated_concepts', handleAssociatedConcepts),
];
