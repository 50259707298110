import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import css from './RefineSuggestionList.module.css';
import IconThumbnail from 'components/IconThumbnail';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {selectSuggestion, changeRefineMode, requestRefineSuggestion} from 'ducks/ui';
import Typography from '@material-ui/core/Typography';
class RefineSuggestionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSelectSuggestion = this.handleSelectSuggestion.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleModeChange(event, newMode){
        this.props.changeRefineMode(newMode);
        let {refineMode, selectedSuggestion, selectedComponents} = this.props;
        this.props.requestRefineSuggestion({refineMode:newMode, selectedComponents, selectedSuggestion});
    }
    handleSelectSuggestion(selectedSuggestion){
        // console.log('refine suggestion', suggestion)
        // this.props.selectRefineSuggestion(suggestion)
        console.log('suggestion selected in refined list', selectedSuggestion)
        this.props.selectSuggestion(selectedSuggestion)
        let {refineMode, selectedComponents} = this.props;
        this.props.requestRefineSuggestion({refineMode, selectedComponents, selectedSuggestion})
    }
    handleScroll(e){
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            //   console.log('reached the end of scroll');
            let {refineMode, selectedSuggestion, selectedComponents} = this.props;
            this.props.requestRefineSuggestion({refineMode, selectedComponents, selectedSuggestion, append:true});
        }
    }
    render() {
        // let {selectedRefineSuggestion} = this.props;
        return (
            <div className={css.container}>
                <Tabs value={this.props.refineMode} 
                    
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    style={{borderBottom:'1px solid #eeeeee'}}
                    
                    onChange={this.handleModeChange}>
                        
                    <Tab label="Style" value="style" style={{minWidth:`${80}px`}} />
                    <Tab label="Layout" value="layout" style={{minWidth:`${80}px`}}  />
                </Tabs>
    
                <div className={css.suggestions} onScroll={this.handleScroll}>
                    {this.props.refineMode==="style"&&this.props.selectedComponents.length===0 && 
                        <p>Please select a component icon.</p>}
                    

                    {this.props.refineSuggestionMsg.length>0&&
                        <Typography variant="caption" display="block" gutterBottom>
                            {this.props.suggestionMsg}
                        </Typography>}
                    {this.props.refineSuggestionList.map((suggestion,i)=>
                        <IconThumbnail key={i} 
                            suggestion={suggestion}
                            // selected = {selectedRefineSuggestion===suggestion}
                            onSelected={this.handleSelectSuggestion}/>
                            )}
                    {this.props.loadingSuggestions&&
                        <Typography variant="caption" display="block" gutterBottom>
                            Loading...
                        </Typography>}
                </div>
            </div>
        );
    }
}

RefineSuggestionList.propTypes = {
    // selectedRefineSuggestion:PropTypes.object,
    refineSuggestionList:PropTypes.array,
    refineMode:PropTypes.string,
    selectedSuggestion:PropTypes.object,
    selectedComponents:PropTypes.array,
    changeRefineMode:PropTypes.func,
    requestRefineSuggestion:PropTypes.func,
    selectSuggestion:PropTypes.func,
    loadingRefinedSuggestions:PropTypes.bool,
    refineSuggestionMsg:PropTypes.string,
};


const mapStateToProps = (state, ownProps) =>{

	return {
        refineMode:state.ui.refineMode,
        selectedSuggestion:state.ui.selectedSuggestion,
        selectedComponents:state.ui.selectedComponents,
        loadingRefinedSuggestions:state.ui.loadingRefinedSuggestions,
        refineSuggestionMsg:state.ui.refineSuggestionMsg,
        // selectedRefineSuggestion:state.ui.selectedRefineSuggestion,
        refineSuggestionList:state.ui.refineSuggestionList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators({
            changeRefineMode,
            requestRefineSuggestion,
            selectSuggestion
		}, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RefineSuggestionList);

