import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selectSuggestion,requestRefineSuggestion, deleteBookmark} from 'ducks/ui';
import IconThumbnail from 'components/IconThumbnail';
import css from './Bookmarks.module.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
class Bookmarks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSelectSuggestion = this.handleSelectSuggestion.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handlePaperLoaded = this.handlePaperLoaded.bind(this);
        this.papers = {};
    }
    handleSelectSuggestion(selectedSuggestion){
        // console.log('refine suggestion', suggestion)
        // this.props.selectRefineSuggestion(suggestion)
        console.log('suggestion selected in bookmarks', selectedSuggestion)
        this.props.selectSuggestion(selectedSuggestion)
        let {refineMode} = this.props;
        this.props.requestRefineSuggestion({refineMode, selectedComponents:[], selectedSuggestion})
    }
    handleDelete(suggestion){
        console.log('delete', suggestion.id);
        this.props.deleteBookmark(suggestion.id);

    }
    componentDidUpdate(prevProps){
        if (prevProps.bookmarks!==this.props.bookmarks || prevProps.bookmarks.length!==this.props.bookmarks.length){
            prevProps.bookmarks.map(b=>{
                if (!this.props.bookmarks.find(nb=>nb.id==b.id) && this.papers[b.id]){//removed
                    delete this.papers[b.id];
                }
                
            })
                
        }
    }
    handleSave(){
        console.log(this.papers, this.props.bookmarks);
        this.props.bookmarks.map((b,i)=>{
            let paper = this.papers[b.id];
            if (!paper){
                return;
            }
            let url = "data:image/svg+xml;utf8," + encodeURIComponent(paper.project.exportSVG({asString:true}));
     
            let link = document.createElement("a");
            link.download = `icon-${i}.svg`;
            link.href = url;
            link.click();
        })
        
    }
    handlePaperLoaded(bookmark, paper){
        this.papers[bookmark.id] = paper;
    }
    render() {
        return (
            <div className={css.container}>
                <div className={css.header}>
                <Typography variant="h6" style={{marginBottom:'0px'}}  gutterBottom>
                    Bookmarks
                </Typography>
                <Button size="small"  className={css.saveAll} onPointerUp={this.handleSave} >
                    <SaveAltIcon style={{marginRight:'5px'}}/> Save All
                </Button>
                </div>

                <div className={css.bookmarks}>
                    {this.props.bookmarks.map((suggestion,i)=>
                        <IconThumbnail key={i} 
                        deletable={true}
                        suggestion={suggestion}
                        // selected = {selectedRefineSuggestion===suggestion}
                        onPaperLoaded={this.handlePaperLoaded}
                        onDelete={this.handleDelete}
                        onSelected={this.handleSelectSuggestion}/>
                    )}
                </div>
            </div>
            
        );
    }
}

Bookmarks.propTypes = {
    selectSuggestion:PropTypes.func,
    refineMode:PropTypes.string,
    bookmarks:PropTypes.array,
    requestRefineSuggestion:PropTypes.func,
    deleteBookmark:PropTypes.func,
};


const mapStateToProps = (state, ownProps) =>{

	return {
        bookmarks:state.ui.bookmarks,
        refineMode:state.ui.refineMode,

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators({
            selectSuggestion,
            requestRefineSuggestion,
            deleteBookmark
		}, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);
