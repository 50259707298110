import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selectComponent, createBookmark, updateComponent, requestRefineSuggestion} from 'ducks/ui';
import {PaperScope} from 'paper';
import Button from '@material-ui/core/Button';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import css from './Canvas.module.css';
import selectionTool from 'utils/selection';
import isEqual from 'lodash.isequal';

var generateID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.paper = new PaperScope();  // always use this to create anything
        this.canvasRef = React.createRef();
        this.handleBookmark = this.handleBookmark.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
	componentWillUnmount(){
		this.paper.clear();
    }
    componentDidMount(){
		// setup 
        this.paper.setup(this.canvasRef.current);
        this.paper.project.currentStyle = {
        };
        // this.paper.settings.applyMatrix = false;
        this.tool = selectionTool.create(this.paper, 
            (changed, mode, sx, sy,p)=>{
                // console.log('changed', changed);
                //recalculate           
                changed.forEach(item=>{
                    let viewSize = this.paper.project.view.viewSize;
                    let w = (item.bounds.width*item.scaling.x)/viewSize.width;
                    let h = (item.bounds.height*item.scaling.y)/viewSize.height;
                    // console.log('item data:',item.data.scale, scaleX, scaleY);
                    
                    let x = (item.position.x - item.bounds.width/2)/viewSize.width;//.subtract(this.paper.project.view.center).x/(viewSize.width/2)
                    let y = (item.position.y - item.bounds.height/2)/viewSize.height;//item.position.subtract(this.paper.project.view.center).y/(viewSize.height/2)
                    // console.log('ITEM', item, item.data.x, item.data.y, x, y, w, h);
                    // let sx 
                    // raster.position = raster.position.add(new paper.Point(viewSize.width/2*component.x,viewSize.height/2*component.y));
                    // this.props.updateComponent({ 
                    //     suggestionIndex:this.props.selectedSuggestion.index,
                    //     componentIndex: item.data.index, 
                    //     w,
                    //     h, 
                    //     x,
                    //     y
                    // });
                }) 
            },
            (selected)=>{
                // console.log('selected', selected);
                if (selected&& selected.length>0){
                    let indices = selected.map(item=>item.data.index);
                    if (isEqual(indices, this.props.selectedComponents)){
                        return;
                    }
                    console.log('selected', selected);
                    this.props.selectComponent(indices);
                    let {refineMode, selectedSuggestion} = this.props;
                    // console.log('requestRefineSuggestion', selected, selectedComponents);
                    this.props.requestRefineSuggestion({refineMode, selectedComponents:indices, selectedSuggestion})
                }else{
                    this.props.selectComponent([]);
                    let {refineMode, selectedSuggestion} = this.props;
                    this.props.requestRefineSuggestion({refineMode, selectedComponents:[], selectedSuggestion})
                }
                
            }
        );
        this.tool.activate();
    }
    componentDidUpdate(prevProps){
        if (prevProps.selectedSuggestion !== this.props.selectedSuggestion){
            console.log('update', this.props.selectedSuggestion);
            let paper = this.paper;
            paper.project.clear();
            paper.activate();
            if (this.props.selectedSuggestion){
                let suggestion = this.props.selectedSuggestion;
                let viewSize = paper.project.view.viewSize;
                let selected = [];
                suggestion.components.forEach(component=>{
                    // let baseURL = process.env.NODE_ENV==='production'? process.env.PUBLIC_URL: 'svg';
                    paper.project.importSVG(`svg/${component.svgfile}`, (item)=>{
                        let data = {
                            index:component.index,
                            width:component.w,
                            height:component.h,
                            x:component.x,
                            y:component.y,
                        }

                        if (!item){
                            item = new paper.Path.Rectangle(
                                new paper.Point(viewSize.width*component.x,viewSize.height*component.y),
                                new paper.Size(viewSize.width*component.w, viewSize.height*component.h)
                            );
                            item.strokeColor = '#000000';
                            item.strokeWidth = 1;
                            item.strokeScaling = false;
                            item.data = data;
                            // return;
                        }else{
                            if (item.className==='Group' && item.clipped===true){
                                let texts = item.getItems({class:paper.TextItem});
                                if (texts.length>0){
                                    texts.forEach(t=>t.remove());
                                    if (item.clipped && item.firstChild.className==='Shape'){
                                        item.firstChild.remove();
                                    }
                                    // paper.project.activeLayer.addChild(item.firstChild);
                                    // item.remove();
                                    
                                }
                            }
                            item.data = data;
                            item.strokeScaling = false;
                            console.log('SCALE:',item);
                            item.scale(viewSize.width*component.w/item.bounds.width, viewSize.height*component.h/item.bounds.height)
                            item.position = new paper.Point(item.bounds.width/2, item.bounds.height/2);
                            item.position = item.position.add(new paper.Point(viewSize.width*component.x,viewSize.height*component.y));
                        }
                        // this will be called only once, i.e., only one item is selectable
                        if (this.props.selectedComponents.includes(component.index)){
                            this.tool.select([item]);
                        }

                       

                    });
                   
                });
                
                

            }
            
        }
    }
    handleBookmark(){
        // console.log('bookmark', this.paper.project.activeLayer.children);
        let {selectedSuggestion} = this.props;
        let bookmark = {
            ...selectedSuggestion
        }
        this.paper.project.activeLayer.children.forEach((item,i)=>{
            // console.log('bookmark-------------------', item, i);
            if (item.guide){
                return;
            }
            let viewSize = this.paper.project.view.viewSize;
            let w = (item.bounds.width*item.scaling.x)/viewSize.width;
            let h = (item.bounds.height*item.scaling.y)/viewSize.height;
            // console.log('item data:',item.data.scale, scaleX, scaleY);
            
            let x = (item.position.x - item.bounds.width/2)/viewSize.width;//.subtract(this.paper.project.view.center).x/(viewSize.width/2)
            let y = (item.position.y - item.bounds.height/2)/viewSize.height;//item.position.subtract(this.paper.project.view.center).y/(viewSize.height/2)

            bookmark = {
                id: generateID(),
                ...bookmark,
                components:bookmark.components.map((component, componentIndex)=>{
                    if (componentIndex!==item.data.index){
                        return component;
                    }
                    return {
                        ...component,
                        w,
                        h,
                        x,
                        y,
                        r:item.rotation
                    }
                })
            }
            // console.log('ITEM', item, item.data.x, item.data.y, x, y, w, h);

        }) 
        // console.log('BOOKMARK::', bookmark);
        this.props.createBookmark(bookmark);
      
    }
    handleSave(){
     
        var url = "data:image/svg+xml;utf8," + encodeURIComponent(this.paper.project.exportSVG({asString:true}));
     
        var link = document.createElement("a");
        link.download = 'icon.svg';
        link.href = url;
        link.click();
    }
    render() {
        return (
            <div className={css.container}>
                <div className={css.menu}>
                    <Button size="small" onPointerUp={this.handleBookmark} >
                        <BookmarkBorderIcon style={{marginRight:'5px'}}/> Bookmark
                    </Button>
                    <Button size="small" onPointerUp={this.handleSave} >
                        <SaveAltIcon style={{marginRight:'5px'}}/> Save
                    </Button>
                </div>
                <canvas className={css.canvas} touch-action="none" ref={this.canvasRef}>
                
                </canvas>
            </div>
           
        );
    }
}

Canvas.propTypes = {
    refineMode:PropTypes.string,
    selectedComponents:PropTypes.array,
    selectedSuggestion:PropTypes.object,
    selectComponent:PropTypes.func,
    updateComponent:PropTypes.func,
    requestRefineSuggestion:PropTypes.func,
    createBookmark:PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
        refineMode:state.ui.refineMode,
        selectedComponents:state.ui.selectedComponents,
        selectedSuggestion:state.ui.selectedSuggestion
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        ...bindActionCreators({
            selectComponent,
            updateComponent,
            requestRefineSuggestion,
            createBookmark
        },dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
