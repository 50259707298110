import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import css from './SuggestionList.module.css';
import {requestSuggestion, selectSuggestion, selectComponent, requestRefineSuggestion} from 'ducks/ui';
import IconThumbnail from 'components/IconThumbnail';
import Typography from '@material-ui/core/Typography';
class SuggestionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSelectSuggestion = this.handleSelectSuggestion.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleSelectSuggestion(selectedSuggestion){
        console.log('suggestion', selectedSuggestion)
        this.props.selectSuggestion(selectedSuggestion)
        this.props.selectComponent([]);
        let {refineMode} = this.props;
        this.props.requestRefineSuggestion({refineMode, selectedComponents:[], selectedSuggestion})
    }
    handleScroll(e){
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            //   console.log('reached the end of scroll');
            let {query, selectedConcepts} = this.props;
            this.props.requestSuggestion({query, concepts:selectedConcepts, append:true});
        }
    }
    render() {
        // let {selected} = this.props;
        return (
            <div className={css.container} onScroll={this.handleScroll}>

                {this.props.suggestionList.map((suggestion,i)=>
                    <IconThumbnail key={i} 
                        suggestion={suggestion}
                        // selected = {selected.index===suggestion.index}
                        onSelected={this.handleSelectSuggestion}/>)
                    
                    // <Paper key={i} className={[css.thumbnail, selected===suggestion?css.selected:null].join(' ')} elevation={0} 
                    //     onPointerUp={this.handlePointerUp.bind(this, suggestion)}>
                    //     <img src={suggestion.icon} width="100%"/>
                    // </Paper>)
                }
                {this.props.loadingSuggestions&&
                <Typography variant="caption" display="block" gutterBottom>
                    Loading...
                </Typography>}
            </div>
        );
    }
}

SuggestionList.propTypes = {
    selectedSuggestion:PropTypes.object,
    refineMode:PropTypes.string,
    selectedComponents:PropTypes.array,
    selectSuggestion:PropTypes.func,
    loadingSuggestions:PropTypes.bool,
    requestSuggestion:PropTypes.func,
    selectedConcepts:PropTypes.array,
    query:PropTypes.string,
    selectComponent:PropTypes.func,
};


const mapStateToProps = (state) => {
	return {
        selectedSuggestion:state.ui.selectedSuggestion,
        loadingSuggestions:state.ui.loadingSuggestions,
        refineMode:state.ui.refineMode,
        selectedComponents:state.ui.selectedComponents,
        suggestionList:state.ui.suggestionList,
        selectedConcepts:state.ui.selectedConcepts,
        query:state.ui.query,
	};
};


const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({
            selectSuggestion,
            requestRefineSuggestion,
            requestSuggestion,
            selectComponent
        }, dispatch)
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(SuggestionList);
