import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Toolbar from './Toolbar';
import Typography from '@material-ui/core/Typography';
import SuggestionList from './SuggestionList';
import Canvas from './Canvas';
import RefineSuggestionList from './RefineSuggestionList';
import Bookmarks from './Bookmarks';
import css from './App.module.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={css.App}>
        <header className="App-header">
          <Typography variant="h4" gutterBottom>
              Generate Icons from Words!
          </Typography>
        </header>
        <main>
          <Toolbar/>
          <div className={css.suggestionContainer}>
            <SuggestionList/>
            <Canvas/>
            <RefineSuggestionList/>
            {/* {this.props.refineMode==='style'&& <StyleEmbedding/>} */}
          </div>
          <Bookmarks/>
  
        </main>
        <footer>
          
        </footer>
      </div>
    );
  }
}


App.propTypes = {
	refineMode:PropTypes.string,
};

const mapStateToProps = (state) => {
	console.log(state);
	return {
		refineMode:state.ui.refineMode
	};
};

const mapDispatchToProps = (dispatch) => {
	return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
