import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import {PaperScope} from 'paper';
import css from './IconThumbnail.module.css';

class IconThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = { showMenu:false }
        this.paper = new PaperScope();
        this.canvasRef = React.createRef();
        this.handlePointerUp = this.handlePointerUp.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    componentWillUnmount() {
        this.paper.clear();
    }
    componentDidMount() {
        this.paper.setup(this.canvasRef.current);
        this.draw(this.paper, this.props.suggestion);

        if (this.props.onPaperLoaded){
            this.props.onPaperLoaded(this.props.suggestion,this.paper);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.suggestion!==this.props.suggestion){

            //clear the project if it's not the same icon
            if (prevProps.suggestion.components.length!==this.props.suggestion.components.length ||
                prevProps.suggestion.components.reduce((equal, comp, idx)=>{
                    console.log(this.props.suggestion.components[idx].filename, comp.filename, equal);
                    if (!equal){
                        return false;
                    }
                    
                    return this.props.suggestion.components[idx].filename==comp.filename;
            }, true)===false){
                console.log(prevProps.suggestion, '-----------------------clear -----------------------', this.props.suggestion);
                this.paper.project.clear();
                this.paper.activate();
            }
            this.draw(this.paper, this.props.suggestion);

        }
    }
    showMenu(){
        this.setState({
            showMenu:true
        });
    }
    hideMenu(){
        this.setState({
            showMenu:false
        });
    }
    handleDelete(){
        if (this.props.onDelete){
            this.props.onDelete(this.props.suggestion);
        }
    }
    draw(paper, suggestion){
        
        let viewSize = paper.project.view.viewSize;
        // let viewCenter = paper.project.view.center;
        if (paper.project.activeLayer.children.length>0){
            paper.project.activeLayer.children.forEach(item=>{
                let component = suggestion.components.find(c=>c.index===item.data.index);
                console.log('found and readjusting');
                if (component){
                    item.scale(viewSize.width*component.w/item.bounds.width, viewSize.height*component.h/item.bounds.height)
                    item.position = new paper.Point(item.bounds.width/2, item.bounds.height/2);
                    item.position = item.position.add(new paper.Point(viewSize.width*component.x,viewSize.height*component.y));
                }
                
            });
        }else{
            // paper.project.clear();
            // paper.activate();
            // console.log('drawing', suggestion);
            suggestion.components.forEach(component=>{
                // calculate size and position  
                
                // let baseURL = process.env.NODE_ENV==='production'? process.env.PUBLIC_URL: 'svg';
                // console.log('baseURL', baseURL);
                paper.project.importSVG(`svg/${component.svgfile}`, (item)=>{
                    if (!item){
                        item = new paper.Path.Rectangle(
                            new paper.Point(viewSize.width*component.x,viewSize.height*component.y),
                            new paper.Size(viewSize.width*component.w, viewSize.height*component.h)
                        );
                        item.strokeColor = '#000000';
                        item.strokeWidth = 1;
                    }else{
                        if (item.className==='Group'){
                            let texts = item.getItems({class:this.paper.TextItem});
                            if (texts.length>0){
                                texts.forEach(t=>t.remove());
                                if (item.clipped && item.firstChild.className==='Shape'){
                                    item.firstChild.remove();
                                }
                            }
                        }
    
                        item.scale(viewSize.width*component.w/item.bounds.width, viewSize.height*component.h/item.bounds.height)
                        item.position = new paper.Point(item.bounds.width/2, item.bounds.height/2);
                        item.position = item.position.add(new paper.Point(viewSize.width*component.x,viewSize.height*component.y));
                        if (component.r){
                            item.rotate(component.r);
                        }
                    }

                    // console.log('component', component.filename, item.width, item.height, item.position);
                    // item.onLoad = null;
                    item.data = {
                        index:component.index,
                        scale:component.scale,
                        x:component.x,
                        y:component.y,
                    }
                })

                // let raster = new paper.Raster({
                //     source:`svg/${component.svgfile}`,
                //     // position: paper.view.center,
                // });
                
                // raster.onLoad = ()=> {
                    
                //     // console.log(this.paper.view.size, viewSize);
                    
                //     // console.log('paper.view.bounds', viewSize, paper.view.bounds);
                //     // let newSize = viewSize.multiply(component.scale);
                //     // raster.fitBounds(paper.view.bounds);
                //     // raster.scale(component.scale);
                //     // console.log(component);
                //     raster.width = viewSize.width*component.w;//newSize.width;
                //     raster.height = viewSize.height*component.h;//newSize.height;
                //     raster.position = new paper.Point(raster.width/2, raster.height/2);
                    
                //     raster.position = raster.position.add(new paper.Point(viewSize.width*component.x,viewSize.height*component.y));
                //     console.log('  component', component.filename, raster.width, raster.height, raster.position);
                //     // // raster.scale(raster.width/newSize.width, raster.height/newSize.height);
                //     // console.log('===raster===', raster.size, raster.position)
                //     // raster.fitBounds(paper.view.bounds);
                //     raster.onLoad = null;
                //     raster.data = {
                //         index:component.index,
                //         scale:component.scale,
                //         x:component.x,
                //         y:component.y,
                //     }
                // };
            });
        }

       

    }
    handlePointerUp(){
        if (this.props.onSelected){
            this.props.onSelected(this.props.suggestion);
        }
    }
    render() { 
        return (
        <div className={css.container} onPointerEnter={this.showMenu} onPointerLeave={this.hideMenu}>
            {this.props.deletable&& this.state.showMenu &&
                <IconButton className={css.deleteButton} aria-label="delete"
                // color="primary"
                size="small" 
                onPointerDown={this.handleDelete}>
                    <DeleteIcon className={css.deleteIcon}/>
                </IconButton>
            }
            {/* <img src={this.props.suggestion.components[0].icon}width="54"/> */}
            <canvas  ref={this.canvasRef}
            className={[css.thumbnail, this.props.selected?css.selected:null].join(' ')}
            onPointerUp={this.handlePointerUp}></canvas> 
        </div>
            );
    }
}
IconThumbnail.propTypes = {
    suggestion: PropTypes.object,
    selected:PropTypes.bool,
    onSelected:PropTypes.func,
    deletable:PropTypes.bool,
    onDelete:PropTypes.func,
    onPaperLoaded:PropTypes.func,
}
IconThumbnail.defaultTypes = {
    deletable:false
}

export default IconThumbnail;